import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { WHITE } from '../../../constants/colors';
import Styles from './InputField.styles';

const IconWrapper = styled.div`${Styles.iconWrapper}`;
const Input = styled.input`${Styles.input}`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore

/**
 * InputField component
 * @param {Object} props - component props
 * @returns {JSX}
 */
const InputField = ({
  autoComplete,
  className,
  disableAutocomplete,
  fixAutofill,
  isBlack,
  isHidden,
  isRequired,
  name,
  onChange,
  placeHolder,
  reference,
  status,
  type,
  value,
}) => {
  return (
    <Wrapper className={className} isHidden={isHidden} fixAutofill={fixAutofill} isBlack={isBlack}>
      <Input
        type={type}
        placeholder={placeHolder}
        ref={reference}
        value={value}
        name={name}
        onChange={onChange}
        autoComplete={disableAutocomplete ? 'off' : autoComplete}
        required={isRequired}
        status={status}
        isBlack={isBlack}
      />
      {status >= 1 && (
        <IconWrapper isBlack={isBlack}>
          <svg width="13px" height="10px" viewBox="0 0 13 10">
            ${status === 1
            ? <path fill={isBlack ? 'green' : WHITE} d="M0 5.75948L4.3747 10l8.22193-8.61039L11.26971 0l-6.895 7.22078-2.95182-2.88585z" fillRule="evenodd" />
            : <path d="M5.933 5.77159l3.62781 3.61818-1.09817 1.17104-1.83701-1.8324L4.8 6.90759l-3.60387 3.6148L0 9.44938 3.665 5.7746.03841 2.1577 1.11144.9616l3.68656 3.677L8.42818 1l1.17104 1.09817L5.933 5.77159z" fill={isBlack ? 'red' : 'yellow'} fillRule="evenodd" />}
          </svg>
        </IconWrapper>
      )}
    </Wrapper>
  );
};

InputField.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  disableAutocomplete: PropTypes.bool,
  fixAutofill: PropTypes.string,
  isBlack: PropTypes.bool,
  isHidden: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  reference: PropTypes.object,
  status: PropTypes.oneOf([0, 1, 2]),
  type: PropTypes.string,
  value: PropTypes.string,
};

InputField.defaultProps = {
  autoComplete: 'on',
  fixAutofill: WHITE,
  type: 'text',
};

export default InputField;
