import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { useDispatch } from 'react-redux';

import { setActiveSlide } from '../../../../store/actions';
import { useIsInView } from '../../../../helpers/hooks';
import ImageFluid from '../../../base/ImageFluid';
import ImageFluidWrapper from '../../../base/ImageFluidWrapper';
import Text from '../../../base/Text';

import Styles from './TypeB.styles';

const ImageWrapper = styled.div`
  ${Styles.imageWrapper}
`;
const Content = styled.div`
  ${Styles.content}
`;
const ContentWrapper = styled.div`
  ${Styles.contentWrapper}
`;
const Wrapper = styled.div`
  ${Styles.wrapper}
`;
const PlaceHolderDesktop = styled(ImageFluid)`
  ${Styles.placeHolderDesktop}
`;
const PlaceHolderMobile = styled(ImageFluid)`
  ${Styles.placeHolderMobile}
`;
const Subtitle = styled(Text)`
  ${Styles.subtitle}
`;
const Title = styled(Text)`
  ${Styles.title}
`;
const Description = styled(Text)`
  ${Styles.description}
`;
const ArrowWrapper = styled.div`
  ${Styles.arrowWrapper}
`;
const Arrow = styled.svg`
  ${Styles.arrow}
`;

/**
 * ActionCard Type A component
 * @param {Object} props - props
 * @returns {JSX}
 */
const TypeB = ({
  backgroundColor,
  backgroundImage,
  backgroundImageMobile,
  breadcrumb,
  callToActionHighlightColor,
  description,
  descriptionColor,
  index,
  isInterior,
  subtitle,
  subtitleColor,
  title,
  titleColor,
}) => {
  const dispatch = useDispatch();
  const [ref, isIntersecting] = useIsInView();

  const theme = {
    backgroundColor,
    callToActionHighlightColor,
    descriptionColor,
    isInterior,
    subtitleColor,
    titleColor,
  };

  if (isIntersecting) {
    dispatch(setActiveSlide(index));
  }

  return (
    <Wrapper ref={ref}>
      <ThemeProvider theme={theme}>
        <ImageWrapper>
          <ImageFluidWrapper>
            <PlaceHolderDesktop fluid={backgroundImage} />
            <PlaceHolderMobile fluid={backgroundImageMobile} />
          </ImageFluidWrapper>
        </ImageWrapper>
        <ContentWrapper>
          <ArrowWrapper>
            <Arrow>
              <defs>
                <polygon id="flecha-a" points="0 0 18.414 0 18.414 18.414 0 18.414" />
                <polygon id="flecha-c" points="0 -13.586 18.414 -13.586 18.414 4.829 0 4.829" />
              </defs>
              <g fill="none" fillRule="evenodd">
                <polygon fill={callToActionHighlightColor} points="0 0 0 32 10.235 16" />
                <g transform="translate(0 13.586)">
                  <mask id="flecha-b" fill="#fff">
                    <use xlinkHref="#flecha-a" />
                  </mask>
                  <path fill="#12008F" d="M-0.0002400006,18.414446 L4.82857207,18.414446 C4.82857207,10.9232273 10.9229873,4.82881207 18.414206,4.82881207 L18.414206,0 C8.26058065,0 -0.0002400006,8.26082065 -0.0002400006,18.414446" mask="url(#flecha-b)" />
                  <mask id="flecha-d" fill="#fff">
                    <use xlinkHref="#flecha-c" />
                  </mask>
                  <path fill="#FFF" d="M-0.0002400006,-13.585634 C-0.0002400006,-3.43200858 8.26058065,4.82881207 18.414206,4.82881207 L18.414206,3.01980663e-14 C10.9229873,3.01980663e-14 4.82857207,-6.09441524 4.82857207,-13.585634 L-0.0002400006,-13.585634 Z" mask="url(#flecha-d)" />
                </g>
              </g>
            </Arrow>
          </ArrowWrapper>
          <div className="container">
            <div className="row">
              <Content className="col-12">
                {breadcrumb}
                {subtitle && (<Subtitle>{subtitle}</Subtitle>)}
                <Title bold>{title}</Title>
                <Description>{description}</Description>
              </Content>
            </div>
          </div>
        </ContentWrapper>
      </ThemeProvider>
    </Wrapper>
  );
};

TypeB.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.object,
  backgroundImageMobile: PropTypes.object,
  breadcrumb: PropTypes.object,
  callToActionHighlightColor: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  descriptionColor: PropTypes.string,
  index: PropTypes.number,
  isInterior: PropTypes.bool,
  subtitle: PropTypes.string,
  subtitleColor: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
};

export default TypeB;
