import { css } from 'styled-components';
import { BLACK } from '../../../../constants/colors';
import { media, rem } from '../../../../helpers/mixins';

export default {
  arrow: css`
    width: 18px;
  `,
  arrowWrapper: ({ theme }) => css`
    display: flex;
    height: 32px;
    left: 0;
    position: absolute;
    top: -16px;
    width: 90%;

    &::before {
      background-color: ${theme.callToActionHighlightColor};
      content: ' ';
      display: block;
      height: 32px;
      width: calc(100% - 20px);
    }
  `,
  content: ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: ${theme.isInterior ? '520px' : '343px'};
    padding-bottom: 40px;
    padding-top: 40px;

    ${media.md`
      justify-content: center;
      min-height: ${theme.isInterior ? '340px' : '430px'};
    `}
  `,
  contentWrapper: ({ theme }) => css`
    background-color: ${theme.backgroundColor || '#D8D8D8'};
    position: relative;
  `,
  description: ({ theme }) => css`
    font-size: ${rem(15)};
    line-height: ${rem(20)};

    ${media.md`
      font-size: ${rem(16)};
      line-height: ${rem(22)};

      ${theme.isInterior && css`
        column-count: 2;
        margin-top: 30px;
      `}
    `}
  `,
  imageWrapper: css`
    height: 0;
    overflow: hidden;
    padding-bottom: 62.5%;
    position: relative;

    ${media.md`
      padding-bottom: 20%;
    `}
  `,
  placeHolderDesktop: css`
    display: none;

    ${media.md`
      display: block;
    `}
  `,
  placeHolderMobile: css`
    ${media.md`
      display: none;
    `}
  `,
  subtitle: ({ theme }) => css`
    color: ${theme.subtitleColor || '#63636D'};
    font-size: ${rem(15)};
    font-weight: 500;
    margin-bottom: 15px;

    ${media.md`
      font-size: ${rem(27)};
      margin-bottom: 20px;
    `}
  `,
  title: ({ theme }) => css`
    color: ${theme.titleColor || BLACK};
    font-size: ${rem(24)};
    font-weight: bold;
    margin-bottom: 30px;

    ${media.md`
      font-size: ${rem(56)};
      margin-bottom: 15px;
    `}
  `,
};
