import React, {
  useCallback,
  useState,
  useRef,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';
import Select from 'react-select';

import {
  validateInputs,
  getKey,
  getMexicanStates,
} from '../../../helpers';
import InputField from '../../base/InputField';
import OptionsInput from '../../base/OptionsInput';
import Text from '../../base/Text';
import Styles from './ContactForm.styles';

const Content = styled.div`${Styles.content}`; // prettier-ignore
const ContentWrapper = styled.div`${Styles.contentWrapper}`; // prettier-ignore
const Description = styled(Text)`${Styles.description}`; // prettier-ignore
const Loading = styled.div`${Styles.loading}`; // prettier-ignore
const Options = styled(OptionsInput)`${Styles.options}`; // prettier-ignore
const Submit = styled.button`${Styles.submit}`; // prettier-ignore
const SubmitMask = styled.div`${Styles.submitMask}`; // prettier-ignore
const SuccessMessage = styled(Text)`${Styles.successMessage}`; // prettier-ignore
const Title = styled(Text).attrs({ bold: true })`${Styles.title}`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore

const INPUTS_INITIAL = {
  city: {
    status: 0,
    value: '',
  },
  lastName: {
    status: 0,
    value: '',
  },
  mail: {
    status: 0,
    value: '',
  },
  name: {
    status: 0,
    value: '',
  },
  phone: {
    status: 0,
    value: '',
  },
  position: {
    status: 0,
    value: '',
  },
  rfc: {
    status: 0,
    value: '',
  },
};
const CONTACT_TYPE_INITIAL = { status: 0, value: '' };
const MEXICAN_STATE_INITIAL = { status: 0, value: '' };
const MOVILIDAD_FORM_ID = 'c7d04b69-70a6-4d9d-b8f8-7d852776208c';

/**
 * ContactForm component
 * @param {Object} props - component props
 * @returns {JSX}
 */
const ContactForm = ({
  description,
  formId,
  formInputValue,
  formOptions,
  portalId,
  title,
}) => {
  const nameRef = useRef();
  const lastNameRef = useRef();
  const phoneRef = useRef();
  const mailRef = useRef();
  const positionRef = useRef();
  const stateRef = useRef();
  const cityRef = useRef();
  const interestedRef = useRef();
  const rfcRef = useRef();
  const [contactType, setContactType] = useState(CONTACT_TYPE_INITIAL);
  const [mexicanState, setMexicanState] = useState(MEXICAN_STATE_INITIAL);
  const [validForm, setValidForm] = useState(false);
  const [sending, setSending] = useState(false);
  const [successStatus, setSuccessStatus] = useState(false);
  const [inputs, setInputs] = useState(INPUTS_INITIAL);
  const [interested, setInterested] = useState([]);
  const mexicanStatesList = getMexicanStates();
  const isMovilidadForm = formId === MOVILIDAD_FORM_ID;
  const emailLabel = isMovilidadForm ? 'Correo' : 'Correo corporativo';

  /**
   * No se
   * @returns {JSX}
   */
  const noOptionsMessage = () => (
    <div>Sin Opciones...</div>
  );

  const handleInputChange = useCallback(event => {
    event.persist();
    const {
      target: { name, type, value }
    } = event;

    let status = validateInputs(value, type) ? 1 : 2;

    // Don't check for corporate email
    if (isMovilidadForm && type === 'email') {
      status = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? 1 : 2;
    }

    setInputs(inputsValues => ({
      ...inputsValues,
      [name]: {
        status,
        value,
      }
    }));
  }, [isMovilidadForm]);

  const handleOptionType = useCallback(event => {
    const {
      target: { name, value }
    } = event;

    if (name === 'state') {
      setMexicanState({
        status: value !== '' ? 1 : 2,
        value,
      });
    } else {
      setContactType({
        status: value !== '' ? 1 : 2,
        value,
      });
    }
  }, []);

  const hasName = getKey(inputs, 'name.status', 0) === 1;
  const hasLastName = getKey(inputs, 'lastName.status', 0) === 1;
  const hasPhone = getKey(inputs, 'phone.status', 0) === 1;
  const hasMail = getKey(inputs, 'mail.status', 0) === 1;
  const hasPosition = getKey(inputs, 'position.status', 0) === 1;
  const hasCity = getKey(inputs, 'city.status', 0) === 1;
  const hasMexicanState = getKey(mexicanState, 'status', 0) === 1;

  useEffect(() => {
    if (hasName
      && hasLastName
      && hasPhone
      && hasMail
      && interested
      && hasPosition
      && hasMexicanState
      && hasCity
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [
    inputs,
    contactType,
    hasName,
    hasLastName,
    hasPhone,
    hasMail,
    interested,
    hasPosition,
    hasMexicanState,
    hasCity,
  ]);

  const focusForm = useCallback(() => {
    if (!hasName) {
      nameRef.current.focus();
    } else if (!hasLastName) {
      lastNameRef.current.focus();
    } else if (!hasPhone) {
      phoneRef.current.focus();
    } else if (!hasMail) {
      mailRef.current.focus();
    } else if (!hasPosition) {
      positionRef.current.focus();
    } else if (!hasMexicanState) {
      stateRef.current.focus();
      setMexicanState({
        ...mexicanState,
        status: 2,
      });
    } else if (!hasCity) {
      cityRef.current.focus();
    }
  }, [
    hasName,
    hasLastName,
    hasPhone,
    hasMail,
    hasPosition,
    hasMexicanState,
    mexicanState,
    hasCity,
  ]);

  const handleSubmit = useCallback(event => {
    if (event) {
      event.preventDefault();

      if (validForm) {
        setSending(true);
      } else {
        focusForm();
      }
    }
  }, [validForm, focusForm]);

  const sendConversion = useCallback(() => {
    if (formInputValue === 'nube' && global.gtag) {
      global.gtag('event', 'conversion', {
        send_to: 'AW-849464033/tP1nCLq1m7oDEOGVh5UD',
      });
    }
  }, [formInputValue]);

  useEffect(() => {
    if (sending) {
      axios
        .post(
          `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
          {
            fields: [
              {
                name: 'firstname',
                value: inputs.name.value,
              },
              {
                name: 'lastname',
                value: inputs.lastName.value
              },
              {
                name: 'email',
                value: inputs.mail.value
              },
              {
                name: 'mobilephone',
                value: inputs.phone.value
              },
              {
                name: 'company',
                value: inputs.position.value
              },
              {
                name: 'city',
                value: inputs.city.value
              },
              {
                name: `interesado_en_${formInputValue}`,
                value: [
                  ...new Set(interested.map(item => item.value)),
                ].join(';'),
              },
              {
                name: 'state',
                value: mexicanState.value
              },
              {
                name: 'rfc',
                value: inputs.rfc.value,
              }
            ],
            skipValidation: true,
          }
        )
        // eslint-disable-next-line no-unused-vars
        .then(({ data }) => {
          setSuccessStatus(true);
          setSending(false);
          sendConversion();
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          setSending(false);
          setSuccessStatus(false);
          setInputs(INPUTS_INITIAL);
          setContactType(CONTACT_TYPE_INITIAL);
          setMexicanState(MEXICAN_STATE_INITIAL);
          setInterested([]);
        });
    }
  }, [
    contactType,
    formId,
    formInputValue,
    inputs,
    interested,
    mexicanState,
    portalId,
    sendConversion,
    sending,
  ]);

  return (
    <Wrapper>
      <form className="container" onSubmit={handleSubmit}>
        <ContentWrapper className="row">
          <Content className="col-12 col-md-4" description>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </Content>
          <Content className="col-12 col-md-8" fields>
            <div className="row">
              <InputField
                className="col-12 col-md-6"
                placeHolder="Nombre"
                onChange={handleInputChange}
                value={inputs.name.value}
                name="name"
                reference={nameRef}
                fixAutofill="transparent"
                status={inputs.name.status}
              />
              <InputField
                className="col-12 col-md-6"
                placeHolder="Apellido"
                onChange={handleInputChange}
                value={inputs.lastName.value}
                name="lastName"
                reference={lastNameRef}
                fixAutofill="transparent"
                status={inputs.lastName.status}
              />
              <InputField
                className="col-12 col-md-6"
                placeHolder="Teléfono"
                onChange={handleInputChange}
                value={inputs.phone.value}
                name="phone"
                type="tel"
                reference={phoneRef}
                fixAutofill="transparent"
                status={inputs.phone.status}
              />
              <InputField
                className="col-12 col-md-6"
                placeHolder={emailLabel}
                onChange={handleInputChange}
                value={inputs.mail.value}
                name="mail"
                type="email"
                reference={mailRef}
                fixAutofill="transparent"
                status={inputs.mail.status}
              />
              <InputField
                className="col-12 col-md-6"
                placeHolder="Empresa"
                onChange={handleInputChange}
                value={inputs.position.value}
                name="position"
                reference={positionRef}
                fixAutofill="transparent"
                status={inputs.position.status}
              />
              <InputField
                className="col-12 col-md-6"
                placeHolder="RFC (opcional)"
                onChange={handleInputChange}
                value={inputs.rfc.value}
                name="rfc"
                reference={rfcRef}
                fixAutofill="transparent"
                status={inputs.rfc.status}
              />
              <Options
                options={mexicanStatesList}
                value={mexicanState.value}
                name="state"
                onChange={handleOptionType}
                reference={stateRef}
                className="col-12 col-md-6"
                status={mexicanState.status}
              />
              <InputField
                className="col-12 col-md-6"
                placeHolder="Ciudad"
                onChange={handleInputChange}
                value={inputs.city.value}
                name="city"
                reference={cityRef}
                fixAutofill="transparent"
                status={inputs.city.status}
              />
              <Select
                className="col-12"
                closeMenuOnSelect={false}
                isMulti
                options={formOptions}
                placeholder="Selecciona las soluciones que te interesan..."
                styles={Styles.selectControl}
                onChange={setInterested}
                ref={interestedRef}
                noOptionsMessage={noOptionsMessage}
              />
              <SubmitMask className="col-12">
                {(sending)
                  ? <Loading />
                  : !successStatus && <Submit type="submit" isActive={validForm}>Enviar</Submit>}
                {successStatus && (
                  <SuccessMessage>¡Muchas gracias!</SuccessMessage>
                )}
              </SubmitMask>
            </div>
          </Content>
        </ContentWrapper>
      </form>
    </Wrapper>
  );
};

ContactForm.propTypes = {
  description: PropTypes.string,
  formId: PropTypes.string,
  formInputValue: PropTypes.string,
  formOptions: PropTypes.array,
  portalId: PropTypes.string,
  title: PropTypes.string,
};

ContactForm.defaultProps = {
  description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit vehicula, vivamus cum pharetra habitant volutpat habitasse morbi vel hendrerit.',
  formInputValue: '',
  formOptions: [],
  title: 'Escríbenos',
};

export default ContactForm;
