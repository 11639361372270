import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { exists, isValidArray } from '../../../helpers';
import Styles from './OptionsInput.styles';

const Select = styled.select`${Styles.select}`; // prettier-ignore
const SelectArrow = styled.div`${Styles.selectArrow};`; // prettier-ignore
const SelectWrapper = styled.div`${Styles.selectWrapper};`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore

/**
 * OptionsInput component
 * @param {string} className - modifier class
 * @param {string} color - optional color
 * @param {string} name - input's name
 * @param {func} onChange - onChange callback
 * @param {Array} options - input's options
 * @param {Object} reference - input ref
 * @param {Object} status - input status
 * @returns {?JSX}
 */
const OptionsInput = ({
  className,
  name,
  onChange,
  options,
  reference,
  status,
  value,
}) => {
  if (!exists(name) || !isValidArray(options)) return null;

  return (
    <Wrapper className={className}>
      <SelectWrapper>
        <Select
          onChange={onChange}
          name={name}
          value={value}
          ref={reference}
          needsData={status === 2}
        >
          {options.map(option => (
            <option value={option.value} key={`inputValue${name}${option.value}`}>
              {option.name}
            </option>
          ))}
        </Select>
        <SelectArrow className="arrow" />
      </SelectWrapper>
    </Wrapper>
  );
};

OptionsInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  reference: PropTypes.object,
  status: PropTypes.oneOf([0, 1, 2]),
  value: PropTypes.string,
};

export default OptionsInput;
