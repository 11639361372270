import { css } from 'styled-components';

import { rem, media } from '../../../helpers/mixins';
import { WHITE, GRAY, BLACK } from '../../../constants/colors';

export default {
  iconWrapper: ({ isBlack }) => css`
    align-items: center;
    display: flex;
    height: 34px;
    justify-content: center;
    position: absolute;
    right: 35px;
    top: 0;
    width: 12px;

    ${media.md`
      right: ${isBlack ? 40 : 25}px;
      height: 38px;
    `}
  `,
  input: ({ isBlack, status }) => css`
    background-color: transparent;
    border: 0;
    border: 1px solid #ccc;
    border-radius: 32px;
    color: ${isBlack ? BLACK : WHITE};
    font-size: ${rem('12px')};
    line-height: ${rem('32px')};
    margin: 0;
    margin-bottom: 20px;
    outline: none;
    padding: 0;
    padding-right: 32px;
    text-indent: 15px;
    width: 100%;

    ::placeholder {
      color: ${isBlack ? GRAY : WHITE};
    }

    &:focus {
      border: 1px solid ${isBlack ? GRAY : WHITE};
    }

    ${media.md`
      border-radius: 36px;
      font-size: ${rem('14px')};
      line-height: ${rem('36px')};
    `}

    ${status === 2 && css`
      border: 1px solid ${isBlack ? 'red' : 'yellow'};
      color: ${isBlack ? GRAY : 'yellow'};
    `}
  `,
  wrapper: ({ fixAutofill, isBlack, isHidden }) => css`
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
        -webkit-text-fill-color: ${isBlack ? BLACK : WHITE} !important;
        background-color: ${fixAutofill};
        transition: background-color 5000s ease-in-out 0s;
    }

    ${media.md`
      &&& {
        padding-left: 5px;
        padding-right: 15px;
      }
    `}

    ${isHidden && css`
      display: none;
    `}
  `,
};
