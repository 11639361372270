import { css } from 'styled-components';

import { rem, media } from '../../../helpers/mixins';
import { WHITE } from '../../../constants/colors';
import arrowDown from '../../../../static/images/arrowDown.svg';


export default {
  select: ({ needsData }) => css`
    appearance: none;
    background-color: transparent;
    background-image: none;
    border: 1px solid ${needsData ? 'yellow' : '#ccc'};
    border-radius: 32px;
    box-shadow: none;
    color: ${needsData ? 'yellow' : WHITE};
    font-size: ${rem('12px')};
    height: 32px;
    outline: none;
    position: relative;
    text-indent: 15px;
    width: 100%;

    &:focus {
      border: 1px solid ${needsData ? 'yellow' : WHITE};
    }

    option {
      color: black;
      font-size: ${rem('16px')};
    }

    ${media.md`
      border-radius: 36px;
      font-size: ${rem('14px')};
      height: 36px;
      line-height: 36px;
    `}
  `,
  selectArrow: css`
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    bottom: 12px;
    height: 6px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    width: 10px;

    ${media.md`
      bottom: 14px;
      right: 14px;
    `}
  `,
  selectWrapper: css`
    position: relative;
  `,
  wrapper: css`
    display: flex;
    flex-direction: column;
  `,
};
